<template>
  <div ref="local" id="local" class="local-wrapper">
    <div class="saq-wrapper" v-loading.fullscreen="fullscreenLoading">
      <div class="content-wrapper" v-if="paymentInfo">
        <div class="logos">
          <div class="merchant-logo">
            <img v-if="paymentInfo.brandConfig && paymentInfo.brandConfig.base64Logo"
              :src="`data:image/png;base64,${paymentInfo.brandConfig.base64Logo}`" style="height: 100%;" />
          </div>
          <div class="card-logo">
            <img v-if="paymentInfo.checkoutModelResp && paymentInfo.checkoutModelResp.base64Logo"
              :src="`data:image/png;base64,${paymentInfo.checkoutModelResp.base64Logo}`" style="height: 100%;" />
          </div>
        </div>
        <div class="info-wrapper">
          <div class="info pc">
            <el-row>
              <el-col :span="12">
                <div class="item">
                  <div class="head">Valor</div>
                  <div class="content">{{ getAmountText(paymentInfo.txnCurrency, paymentInfo.txnAmount) }}</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="item">
                  <div class="head">Deadline</div>
                  <div class="content">{{ formatDate(paymentInfo.presentContext.expirationDate ||
                    paymentInfo.presentContext.expiredDate) }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="info mobile">
            <div class="item margin-bottom-6">
              <div class="head">Deadline</div>
              <div class="content">{{ formatDate(paymentInfo.presentContext.expirationDate ||
                paymentInfo.presentContext.expiredDate) }}</div>
            </div>
            <div class="item">
              <div class="head">Valor</div>
              <div class="content amount">{{ getAmountText(paymentInfo.txnCurrency, paymentInfo.txnAmount) }}</div>
            </div>
          </div>
          <div class="use-tips margin-top-20">Abra o app com sua chave PIX cadastrada, escolha Pagar com Pix e escaneie
            o QR Codeou copie e cole o código.</div>
          <div class="qrcode">
            <div class="card">{{ paymentInfo.checkoutModelResp.cardName }}</div>
            <div class="ticket-item"
              v-if="paymentInfo.presentContext.pixCopyAndPaste || paymentInfo.presentContext.paymentCode">
              <div class="ticket">{{ paymentInfo.presentContext.pixCopyAndPaste ||
                paymentInfo.presentContext.paymentCode }}</div>
              <el-button class="button" type="text" @click="copyTicket">
                <span class="iconfont icon-rongqi"></span>
              </el-button>
            </div>
            <div class="scan">Imagem do bilhete:</div>
            <div class="img">
              <img :src="`data:image/png;base64,${paymentInfo.presentContext.base64Image ||
                paymentInfo.presentContext.qrCode}`" />
            </div>
            <div class="copy">
              <!-- 移动端显示复制 -->
              <!-- <el-button @click="copyTicket" class="button btn-copy" type="primary">PIX CÓPIAÉ COLA</el-button> -->
              <div @click="copyTicket" class="button btn-copy">
                PIX CÓPIAÉ COLA
                <img class="copy-icon" src="~@/assets/images/copy.png" />
              </div>
              <!-- PC 显示下载 -->
              <el-button @click="download" class="button download" type="primary">Baixar ticket</el-button>
            </div>
            <img class="bottom-logo" v-if="paymentInfo.checkoutModelResp && paymentInfo.checkoutModelResp.base64Logo"
              :src="`data:image/png;base64,${paymentInfo.checkoutModelResp.base64Logo}`" />
          </div>
          <div class="tips">
            <div class="head">Instrução de pagamento</div>
            <div class="content">
              1. Copie o ID da transação<br />
              2. Utilize o ID para identificar os seus pagamentos PIX na sua aplicação bancária ou leia o código QR na
              aplicação do banco.
            </div>
          </div>
        </div>
        <!-- <div class="return">
          <el-link type="primary" :href="paymentInfo.returnUrl">
            <span class="iconfont icon-fanhui"></span>Voltar
          </el-link>
        </div> -->
      </div>
      <div v-if="showErrorMsg" class="error-msg">
        <img class="unknow" src="~@/assets/images/unknow.png" />
        <div class="tips">O pedido atual expirou. Pague novamente ou entre em contato com o comerciante para saber mais
          sobre os resultados da transação.</div>
      </div>
    </div>
  </div>
</template>
<script>
import FileSaver from 'file-saver';
import { toJpeg, toBlob } from 'html-to-image';
import moment from 'moment';

import { getLocalPaymentInfo } from '@/api/checkout';
import { copyString } from '@/utils/tools/utils';

export default {
  data() {
    return {
      fullscreenLoading: true,
      txnId: '',
      paymentInfo: null,
      showErrorMsg: false,
    };
  },
  created() {
    this.txnId = this.$route.query.txnId;
    getLocalPaymentInfo(this.txnId).then((res) => {
      this.fullscreenLoading = false;
      if (res.respCode === '20000') {
        this.paymentInfo = res.data;
        if (res.data.brandConfig) {
          this.$store.dispatch('app/setBrandInfo', res.data.brandConfig);
        }
        if (!res.data) {
          this.showErrorMsg = true;
        }
      } else {
        this.showErrorMsg = true;
      }
    }).catch(() => {
      this.showErrorMsg = true;
      this.fullscreenLoading = false;
    });
  },
  methods: {
    copyTicket() {
      copyString(this.paymentInfo.presentContext.pixCopyAndPaste || this.paymentInfo.presentContext.paymentCode);
    },
    download() {
      toBlob(this.$refs.local)
        .then(() => {
          // Fix Safari 会显示图片失败，使用再次绘制处理
          toBlob(this.$refs.local)
            .then((blob) => {
              if (window.saveAs) {
                window.saveAs(blob, 'payment_code.jpg');
              } else {
                FileSaver.saveAs(blob, 'payment_code.jpg');
              }
            }).catch((error) => {
              console.log('download page error', error);
            });
        }).catch((error) => {
          console.log('download page error', error);
        });
    },
    formatDate(dateStr) {
      try {
        if (!dateStr) {
          return;
        }
        return moment(dateStr).format('DD/MM/YYYY');
      } catch (err) {
        //
      }
      return dateStr;
    },
    getAmountText(currency, amount) {
      const c = currency === 'BRL' ? 'R$' : currency;
      const a = amount ? `${amount}`.replaceAll('.', ',') : amount;
      return `${c} ${a}`;
    },
  },
};
</script>
<style lang="scss">
.local-wrapper {
  min-height: calc(100vh - 80px);
  background-color: white;
}

.saq-wrapper {
  width: 1030px;
  max-width: 100%;
  margin: 0 auto;

  // height: 500px;
  .content-wrapper {
    padding: 0 16px;

    // height: 100px;
    .logos {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .card-logo {
        height: 50px;
      }

      .merchant-logo {
        height: 50px;
      }
    }

    .info-wrapper {
      text-align: center;
      background: #FAFAFA;
    }

    .info {
      background: #EEEEEE;
      height: 144px;

      &.mobile {
        display: none;
      }

      &.pc {
        display: block;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 144px;
      }

      .head {
        font-weight: 900;
        font-size: 18px;
        color: #27272B;
        line-height: 25px;
      }

      .content {

        font-weight: 900;
        font-size: 18px;
        color: #27272B;
        line-height: 38px;
        margin-top: 4px;
        max-width: 100%;
        white-space: nowrap;
      }
    }

    .use-tips {
      display: none;
      font-size: 12px;
      color: #27272B;
      line-height: 18px;
      text-align: center;
    }

    .qrcode {
      width: 660px;
      max-width: 100%;
      margin: 40px auto 0;

      .ticket-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        gap: 10px;

        .button {
          // display: none;
          padding: 0;

          .icon-rongqi {
            font-size: 20px;
            color: var(--color-primary);
          }
        }
      }

      .ticket {
        border-radius: 2px;
        border: 1px solid #616D7A;
        padding: 0 12px;
        white-space: nowrap;
        overflow-x: scroll;
        text-overflow: ellipsis;
        max-width: 100%;
        font-weight: 500;
        font-size: 20px;
        color: #27272B;
        line-height: 27px;
        height: 43px;
        display: flex;
        align-items: center;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .card {
        font-weight: 900;
        font-size: 24px;
        color: #27272B;
        line-height: 33px;
      }

      .scan {
        margin-top: 40px;
        font-weight: 900;
        font-size: 20px;
        color: #27272B;
        line-height: 27px;
      }

      .img {
        width: 300px;
        height: 300px;
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 0px;
        border: 1px solid #616D7A;
        background: #FFFFFF;

        img {
          height: 100%;
        }
      }

      .copy {
        margin-top: 30px;

        .ticket {
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #616D7A;
          line-height: 42px;
          font-weight: 500;
          font-size: 16px;
          color: #27272B;
          text-align: left;
          padding: 0 12px;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .button {
          width: 100%;
          // margin-top: 20px;

          &.btn-copy {
            display: none;
          }
        }
      }

      .bottom-logo {
        display: none;
        margin: 0 auto;
        margin-top: 24px;
        height: 36px;
        width: auto;

        // width: 120px;
      }
    }

    .tips {
      text-align: left;
      width: 660px;
      max-width: 100%;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 36px;

      .head {
        font-weight: 900;
        font-size: 16px;
        color: #27272B;
        line-height: 22px;
      }

      .content {
        font-weight: 500;
        font-size: 16px;
        color: #616D7A;
        line-height: 22px;
        margin-top: 13px;
      }
    }

    .return {
      padding: 50px 0 130px;
      color: var(--color-primary);
      font-weight: 500;
      font-size: 16px;

      .el-link--primary {
        font-size: 16px;
        line-height: 22px;
        color: var(--color-primary);
      }

      .icon-fanhui {
        margin-right: 5px;
      }
    }
  }

  .error-msg {
    padding: 200px 15px 0;
    width: 690px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

    .unknow {
      width: 134px;
      height: auto;
    }

    .tips {
      font-weight: 500;
      font-size: 18px;
      color: #616D7A;
      line-height: 25px;
      text-align: left;
      margin-top: 40px;
    }
  }
}

@media screen and(max-width: 1140px) {
  .local-wrapper {
    min-height: calc(100vh - 210px);
  }

  .saq-wrapper {
    .content-wrapper {
      .logos {
        display: none;
        height: 50px;

        .card-logo {
          height: 30px;
        }

        .merchant-logo {
          height: 30px;
        }
      }

      .info-wrapper {
        background-color: transparent;
      }

      .info {
        height: 70px;
        margin-top: 30px;
        background-color: transparent;
        padding-bottom: 30px;
        border-bottom: 1px solid #EFEFF4;

        &.pc {
          display: none;
        }

        &.mobile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          padding-bottom: 20px;
        }

        .item {
          height: auto;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 10px;
          align-items: baseline;

          .head {
            font-size: 14px;
          }

          .content {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #27272B;

            &.amount {
              font-weight: 1000;
              font-size: 24px;
              color: #000000;
              line-height: 22px;
            }
          }
        }
      }

      .use-tips {
        display: block;
        background: #FFF3F3;
        border-radius: 4px;
        padding: 7px 8px;
        font-size: 14px;
      }

      .qrcode {
        margin-top: 0;
        // padding-left: 8px;
        // padding-right: 8px;
        box-sizing: border-box;

        .ticket-item {
          display: none;
        }

        .ticket {
          display: none;
          font-size: 12px;
          line-height: 17px;
        }

        .card {
          display: none;
          font-size: 14px;
          line-height: 19px;
        }

        .img {
          width: 300px;
          height: 300px;
          border: none;
        }

        .scan {
          display: none;
          margin-top: 15px;
          font-size: 14px;
          line-height: 19px;
        }

        .copy {
          margin-top: 15px;

          .button {
            &.btn-copy {
              position: relative;
              display: block;
              height: 56px;
              background: #01A2DD;
              border-radius: 8px;
              color: #FFFFFF;
              font-size: 16px;
              line-height: 56px;
              font-weight: 900;

              .copy-icon {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
              }
            }

            &.download {
              display: none;
            }
          }
        }

        .bottom-logo {
          display: block;
        }
      }

      .tips {
        display: none;
        padding-top: 20px;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;

        .head {
          font-size: 12px;
          line-height: 17px;
        }

        .content {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }

  .layout-wrapper {
    background-color: white !important;

    .lang.mobile {
      display: none;
    }
  }
}
</style>
